import React from 'react';
import {
  CenterFlexCol,
  Title,
  Msg,
} from '../components/common/styledComponents';
import { pageNotFoundError } from '../constants/errorsMsgs';

export default () => (
  <CenterFlexCol>
    <Title>404</Title>
    <Msg>{pageNotFoundError}</Msg>
  </CenterFlexCol>
);
